import "bootstrap/dist/css/bootstrap.css";
import { RouterProvider, createBrowserRouter, redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import './App.css';
import { AuthPage } from './pages/auth';
import { ChangePassword } from "./pages/auth/changePassword";
import { InboundPage } from './pages/inbound';
import { InventoryPage } from './pages/inventory';
import { Logout } from "./pages/logout";
import { MainPage } from './pages/main';
import { OutboundPage } from './pages/outbound';
import { ProductPage } from "./pages/product";
import { getCurrentUser } from './services/authService';

const router = createBrowserRouter([
  {
    path: "/",
    element: <AuthPage />,
    errorElement: <div><h1>Page Not Found</h1></div>,
    loader: () => {
      const user = getCurrentUser();
      if (user) {
        return redirect("/home");
      }
      return null;
    }
  },
  {
    path: "/home",
    element: <MainPage />,
    loader: () => {
      const user = getCurrentUser();
      if (!user || user['role'] !== "User") {
        return redirect("/");
      }
      return null;
    }
  },
  {
    path: "/order/outbound",
    element: <OutboundPage />,
    loader: () => {
      const user = getCurrentUser();
      if (!user || user['role'] !== "User") {
        return redirect("/");
      }
      return null;
    }
  },
  {
    path: "/order/inbound",
    element: <InboundPage />,
    loader: () => {
      const user = getCurrentUser();
      if (!user || user['role'] !== "User") {
        return redirect("/");
      }
      return null;
    }
  },
  {
    path: "/inventory",
    element: <InventoryPage />,
    loader: () => {
      const user = getCurrentUser();
      if (!user || user['role'] !== "User") {
        return redirect("/");
      }
      return null;
    }
  },
  {
    path: "/product",
    element: <ProductPage />,
    loader: () => {
      const user = getCurrentUser();
      if (!user || user['role'] !== "User") {
        return redirect("/");
      }
      return null;
    }
  },
  {
    path: "/logout",
    element: <Logout />,
    loader: () => {
      const user = getCurrentUser();
      if (!user || user['role'] !== "User") {
        return redirect("/");
      }
      return null;
    }
  },
  {
    path: "/password",
    element: <ChangePassword />,
    loader: () => {
      const user = getCurrentUser();
      if (!user || user['role'] !== "User") {
        return redirect("/");
      }
      return null;
    }
  }
])

function App() {
  return (
    <>
      <ToastContainer />
      <RouterProvider router={router} />
    </>
  );
}

export default App;
