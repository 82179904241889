import React, { useState } from "react";
import * as Fa from 'react-icons/fa';
import { toast } from "react-toastify";
import { AuthButton, DataInputWrapper, EyesWrapper, FormWrapper } from "../../../components/form/style";
import { MainSectionWrapper } from "../../../sections/main/style";
import { SectionWrapper } from "../../../sections/style";
import { getAuthHeaders } from "../../../services/authService";
import httpService from "../../../services/httpService";
import { MainPageWrapper } from "../../main/style";

export const ChangePassword = () => {
    const [data, setData] = useState({})
    const [openOldPassword, setOpenOldPassword] = useState(false);
    const [openNewPassword, setOpenNewPassword] = useState(false);

    const onSubmit = async (e) => {
        e.preventDefault();

        try {
            const { status } = await httpService.put("client/user/password", data, getAuthHeaders());
            if (status === 200) {
                toast.success("Berhasil update password!");
                setTimeout(() => {
                    window.location = "/"
                }, 500)
            }
        } catch (e) {
            if (e.response && e.response.status <= 500) {
                console.log(e);
                toast.error(e.response.data.message);
            }
        }
    }

    const onChange = ({ currentTarget: input }) => {
        data[input.name] = input.value;
        setData(data);
    }
    return (
        <MainPageWrapper>
            <MainSectionWrapper>
                <SectionWrapper>
                    <FormWrapper>
                        <form onSubmit={onSubmit}>
                            <DataInputWrapper style={{ width: "300px", display: "flex", flexDirection: "column" }}>
                                <label>Old Password</label>
                                <input type={openOldPassword ? "text" : "password"} placeholder="Old Password" onChange={onChange} name="old_password" />
                                <EyesWrapper onClick={() => setOpenOldPassword(!openOldPassword)}>
                                    {openOldPassword ? <Fa.FaRegEye /> : <Fa.FaRegEyeSlash />}
                                </EyesWrapper>
                            </DataInputWrapper>
                            <DataInputWrapper style={{ width: "300px", display: "flex", flexDirection: "column" }}>
                                <label>New Password</label>
                                <input type={openNewPassword ? "text" : "password"} placeholder="New Password" onChange={onChange} name="new_password" />
                                <EyesWrapper onClick={() => setOpenNewPassword(!openNewPassword)}>
                                    {openNewPassword ? <Fa.FaRegEye /> : <Fa.FaRegEyeSlash />}
                                </EyesWrapper>
                            </DataInputWrapper>
                            <AuthButton style={{ width: "300px" }}>
                                Update
                            </AuthButton>
                        </form>
                    </FormWrapper>
                </SectionWrapper>
            </MainSectionWrapper>
        </MainPageWrapper>
    )
}