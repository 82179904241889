import React, { useEffect, useState } from "react";
import { CartesianGrid, Cell, Label, Legend, Line, LineChart, Pie, PieChart, Tooltip, XAxis, YAxis } from "recharts";
import { Navbar } from "../../components/navbar";
import { getInventory, getInventoryChart } from "../../services/inventoryService";
import { getInboundOrders, getInboundOrdersChart, getInboundOutboundOrdersChart, getOutboundOrders, getOutboundOrdersChart } from "../../services/orderService";
import { getProducts } from "../../services/productService";
import { CardContent, CardWrapper, ChartHeader, ChartWrapper, ContentHeaderChart, MainContent, MainContentHeader, MainHeaderGreet, MainSectionWrapper, RowHeader } from "./style";

export const MainSection = () => {
    const chartColors = ['#e63946', '#edae49', '#52489c', '#3376BD'];

    const [inboundCharts, setInboundCharts] = useState([]);
    const [outboundCharts, setOutboundCharts] = useState([]);
    const [inbountOutbound, setInboundOutbound] = useState([]);
    const [inventories, setInventories] = useState([]);
    const [inventory, setInventory] = useState([]);
    const [inventoryPayload, setInventoryPayload] = useState([]);
    const [products, setProducts] = useState([]);
    const [outbounds, setOutbounds] = useState([]);
    const [inbounds, setInbounds] = useState([]);

    const fetchChartData = async () => {
        const [inbound, outbound, inbout, inventory, inventories, products, outbounds, inbounds] = await Promise.all(
            [getInboundOrdersChart(), getOutboundOrdersChart(), getInboundOutboundOrdersChart(), getInventoryChart(), getInventory(), getProducts(), getOutboundOrders(), getInboundOrders()]
        );
        if (inbound.status === 200) {
            setInboundCharts(inbound.data.data);
        }

        if (outbound.status === 200) {
            setOutboundCharts(outbound.data.data);
        }

        if (inbout.status === 200) {
            setInboundOutbound(inbout.data.data);
        }

        if (inventory.status === 200) {
            setInventory(inventory.data.data);
            let inventPayload = [];
            inventory.data.data.forEach((invent, index) => {
                inventPayload.push({ value: invent['category'], type: 'circle', id: index, color: chartColors[index % chartColors.length] });
            })
            setInventoryPayload(inventPayload);
        }

        if (inventories.status === 200) {
            setInventories(inventories.data.data)
        }

        if (products.status === 200) {
            setProducts(products.data.data);
        }

        if (outbounds.status === 200) {
            setOutbounds(outbounds.data.data);
        }

        if (inbounds.status === 200) {
            setInbounds(inbounds.data.data);
        }
    }

    useEffect(() => {
        fetchChartData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <MainSectionWrapper>
            <Navbar />
            <MainContent>
                <MainContentHeader>
                    <MainHeaderGreet>
                        <h1>Dashboard</h1>
                        <p>Let's check your update today</p>
                    </MainHeaderGreet>
                </MainContentHeader>
                <RowHeader>
                    <CardWrapper>
                        <CardContent>
                            <h1>Products</h1>
                            <h2>{products.length}</h2>
                            <span>Total Products</span>
                        </CardContent>
                    </CardWrapper>
                    <CardWrapper>
                        <CardContent>
                            <h1>Inventory</h1>
                            <h2>{inventories.length}</h2>
                            <span>Total Inventory Stored</span>
                        </CardContent>
                    </CardWrapper>
                    <CardWrapper>
                        <CardContent>
                            <h1>Inbound</h1>
                            <h2>{inbounds.length}</h2>
                            <span>Total Inbounds</span>
                        </CardContent>
                    </CardWrapper>
                    <CardWrapper>
                        <CardContent>
                            <h1>Outbound</h1>
                            <h2>{outbounds.length}</h2>
                            <span>Total Outbounds</span>
                        </CardContent>
                    </CardWrapper>
                </RowHeader>
                <ContentHeaderChart>
                    <ChartWrapper>
                        <ChartHeader>
                            <h1>Inbound</h1>
                        </ChartHeader>
                        <LineChart width={600} height={300} data={inboundCharts}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" tick={{ fontSize: 12, fontWeight: "500", dy: 5 }}
                            />
                            <YAxis tick={{ fontSize: 13, dx: -5 }} />
                            <Tooltip />
                            <Legend iconType="circle" wrapperStyle={{ fontSize: 13 }} iconSize={12} payload={[
                                { value: 'Inbound Order', type: 'square', id: 'inbound', color: '#00798c' },
                                // Add more legend items if needed
                            ]} />
                            <Line type="monotone" dataKey="count" stroke="#8884d8" />
                        </LineChart>
                        {/* <BarChart width={600} height={300} data={inboundCharts}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" tick={{ fontSize: 12, fontWeight: "500", dy: 5 }}
                            />
                            <YAxis tick={{ fontSize: 13, dx: -5 }} />
                            <Tooltip />
                            <Legend iconType="circle" wrapperStyle={{ fontSize: 13 }} iconSize={12} payload={[
                                { value: 'Inbound Order', type: 'square', id: 'inbound', color: '#00798c' },
                                // Add more legend items if needed
                            ]} />
                            <Bar type="monotone" fill="#00798c" dataKey="count" />
                        </BarChart> */}
                    </ChartWrapper>
                    <ChartWrapper>
                        <ChartHeader>
                            <h1>Outbound</h1>
                        </ChartHeader>

                        <LineChart width={600} height={300} data={outboundCharts}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" tick={{ fontSize: 12, fontWeight: "500", dy: 5 }}
                            />
                            <YAxis tick={{ fontSize: 13, dx: -5 }} />
                            <Tooltip />
                            <Legend iconType="circle" wrapperStyle={{ fontSize: 13 }} iconSize={12} payload={[
                                { value: 'Inbound Order', type: 'square', id: 'inbound', color: '#00798c' },
                                // Add more legend items if needed
                            ]} />
                            <Line type="monotone" dataKey="count" stroke="#8884d8" />
                        </LineChart>
                        {/* <BarChart width={600} height={300} data={outboundCharts}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" tick={{ fontSize: 12, fontWeight: "500", dy: 5 }}
                            />
                            <YAxis tick={{ fontSize: 13, dx: -5 }} />
                            <Tooltip />
                            <Legend iconType="circle" wrapperStyle={{ fontSize: 13 }} iconSize={12} payload={[
                                { value: 'Outbound Order', type: 'square', id: 'outbound', color: '#3376BD' },
                                // Add more legend items if needed
                            ]} />
                            <Bar type="monotone" fill="#3376BD" dataKey="count" />
                        </BarChart> */}
                    </ChartWrapper>
                </ContentHeaderChart>
                <ContentHeaderChart>
                    <ChartWrapper>
                        <ChartHeader>
                            <h1>Inbound/Outbound</h1>
                        </ChartHeader>
                        <LineChart width={600} height={300} data={inbountOutbound}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" tick={{ fontSize: 12, fontWeight: "500", dy: 5 }}
                            />
                            <YAxis tick={{ fontSize: 13, dx: -5 }} />
                            <Tooltip />
                            <Legend iconType="circle" wrapperStyle={{ fontSize: 13 }} iconSize={12} payload={[
                                { value: 'Inbound Order', type: 'square', id: 'inbound', color: '#00798c' },
                                { value: 'Outbound Order', type: 'square', id: 'outbound', color: '#3376BD' },
                                // Add more legend items if needed
                            ]} />
                            <Line type="monotone" dataKey="inbound_count" stroke="#00798c" />
                            <Line type="monotone" dataKey="outbound_count" stroke="#3376BD" />
                        </LineChart>
                        {/* <BarChart width={600} height={300} data={inbountOutbound}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" tick={{ fontSize: 12, fontWeight: "500", dy: 5 }}
                            />
                            <YAxis tick={{ fontSize: 13, dx: -5 }} />
                            <Tooltip />
                            <Legend iconType="circle" wrapperStyle={{ fontSize: 13, marginTop: 20 }} iconSize={12} payload={[
                                { value: 'Inbound Order', type: 'square', id: 'inbound', color: '#00798c' },
                                { value: 'Outbound Order', type: 'square', id: 'outbound', color: '#3376BD' },
                                // Add more legend items if needed
                            ]} />
                            <Bar fill="#00798c" dataKey="inbound_count" />
                            <Bar fill="#3376BD" dataKey="outbound_count" />
                        </BarChart> */}
                    </ChartWrapper>
                    <ChartWrapper>
                        <ChartHeader>
                            <h1>Inventory</h1>
                        </ChartHeader>
                        <PieChart width={600} height={300} data={inventory}>
                            <Pie
                                dataKey="count"
                                isAnimationActive={false}
                                data={inventory}
                                cx="50%"
                                cy="50%"
                                outerRadius={80}
                                fill="#8884d8"
                                label
                            >
                                {inventory.map((invent, index) => (
                                    <Cell key={`cell-${index}`} fill={chartColors[index % chartColors.length]}>
                                        <Label
                                            position="inside"
                                            value={invent.count}
                                            fill="#fff"
                                            fontSize={12} />
                                    </Cell>
                                ))}
                            </Pie>
                            <Tooltip />
                            <Legend iconType="circle" wrapperStyle={{ fontSize: 13, marginTop: 20 }} iconSize={12} payload={inventoryPayload} />
                        </PieChart>
                    </ChartWrapper>
                </ContentHeaderChart>
            </MainContent>
        </MainSectionWrapper>
    )
}