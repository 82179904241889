import React from "react";
import avatar from '../../assets/images/avatar.jpeg';
import { AuthWrapper, Avatar, AvatarWrapper, MainSectionNavbar, MainSectionNavbarContainer } from "../../sections/main/style";
import { getCurrentUser } from "../../services/authService";

const user = getCurrentUser();

export const Navbar = () => {
    return (
        <MainSectionNavbar>
            <MainSectionNavbarContainer>
                <AuthWrapper>
                    <Avatar>
                        <img src={avatar} alt="avatar" />
                    </Avatar>
                    <AvatarWrapper>
                        <span>{user['role']} ({user['source']})</span>
                        <p>{user['full_name']}</p>
                    </AvatarWrapper>
                </AuthWrapper>
            </MainSectionNavbarContainer>
        </MainSectionNavbar>
    )
}