import styled from "styled-components";

export const AuthContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
`;

export const AuthWrapper = styled.div`
    background-color: #fff;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    padding: 2rem;
    border-radius: .5rem;

    @media screen and (min-width: 1024px) {
        min-width: 450px;
    }
`;

export const AuthHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    & > img {
        width: 100px;
        margin-bottom: 1.75rem;
    }

    & > h1 {
        font-size: 1.25rem;
        font-weight: 700;
        margin-bottom: 9px;
    }

    & > p {
        font-size: 13.5px;
        color: rgb(107 114 128);
    }
`;