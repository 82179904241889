import React from "react";
import { Sidebar } from "../../components/sidebar";
import { InboundList } from "../../sections/order/inbound";
import { MainPageWrapper } from "../main/style";

export const InboundPage = () => {
    return (
        <MainPageWrapper>
            <Sidebar />
            <InboundList />
        </MainPageWrapper>
    )
}