import React from "react";
import logo from '../../assets/images/coolkas.png';
import { AuthForm } from "../../components/form/auth";
import { AuthContainer, AuthHeader, AuthWrapper } from "./style";

export const AuthPage = () => {
    return (
        <AuthContainer>
            <AuthWrapper>
                <AuthHeader>
                    <img src={logo} alt="logo" />
                    <h1>Welcome Back</h1>
                    <p>Please enter your credentials to sign in</p>
                </AuthHeader>
                <AuthForm />
            </AuthWrapper>
        </AuthContainer>
    )
}