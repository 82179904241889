import React from "react";
import { Sidebar } from "../../components/sidebar";
import { ProductSection } from "../../sections/product";
import { MainPageWrapper } from "../main/style";

export const ProductPage = () => {
    return (<MainPageWrapper>
        <Sidebar />
        <ProductSection />
    </MainPageWrapper>)
}