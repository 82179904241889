import React from "react";
import {
  ModalContent,
  ModalContentDetail,
  ModalContentImage,
  ModalContentWrapper,
  ModalHeader,
  ModalHeaderContainer,
  ModalWrapper,
  OrderItem,
  OrderItemDetail,
  OrderItemFlex,
  OrderItems,
} from "../../style";

export const OutboundOrderModal = ({ order }) => {
  return (
    <ModalWrapper>
      <ModalHeader>
        <ModalHeaderContainer>
          <h1>Order Details</h1>
        </ModalHeaderContainer>
      </ModalHeader>
      <ModalContent>
        <ModalContentWrapper>
          <h1>Detail</h1>
          <ModalContentDetail>
            <h2>Owner</h2>
            <span>{order["source"]}</span>
          </ModalContentDetail>
          <ModalContentDetail>
            <h2>SO Number</h2>
            <span>{order["so_number"]}</span>
          </ModalContentDetail>
          <ModalContentDetail>
            <h2>PO Number</h2>
            <span>{order["po_number"]}</span>
          </ModalContentDetail>
          <ModalContentDetail>
            <h2>Order Number</h2>
            <span>{order["order_number"]}</span>
          </ModalContentDetail>
          <ModalContentDetail>
            <h2>Customer</h2>
            <span>{order["customer_name"]}</span>
          </ModalContentDetail>
          <ModalContentDetail>
            <h2>Delivery Date</h2>
            <span>{new Date(order["delivery_date"]).toLocaleDateString()}</span>
          </ModalContentDetail>
        </ModalContentWrapper>
        <ModalContentWrapper>
          <h1>Items</h1>
          <OrderItems>
            {order["items"].map((item, index) => (
              <OrderItem key={index}>
                <OrderItemFlex style={{ flex: "2" }}>
                  <span>
                    {item["quantity"]} {item["uom"]}
                  </span>
                  <OrderItemDetail>
                    <h3>{item["product_name"]}</h3>
                    <span>{item["material_number"]}</span>
                  </OrderItemDetail>
                </OrderItemFlex>
                <OrderItemFlex style={{ flex: "1" }}>
                  <OrderItemDetail>
                    <h3>Expired</h3>
                    <p>
                      {new Date(item["expiry_date"]).toLocaleDateString(
                        "id-ID",
                        {
                          day: "2-digit",
                          month: "long",
                          year: "numeric",
                        }
                      )}
                    </p>
                  </OrderItemDetail>
                  <OrderItemDetail>
                    <h3>Batch</h3>
                    <p>{item["batch"]}</p>
                  </OrderItemDetail>
                </OrderItemFlex>
              </OrderItem>
            ))}
          </OrderItems>
        </ModalContentWrapper>
        <ModalContentWrapper>
          <h1>Transport</h1>
          <ModalContentDetail>
            <h2>Driver</h2>
            <span>{order["driver"]}</span>
          </ModalContentDetail>
          <ModalContentDetail>
            <h2>Vehicle</h2>
            <span>{order["vehicle"]}</span>
          </ModalContentDetail>
          <ModalContentDetail>
            <h2>Last Activity</h2>
            <span>
              {order["updated_at"] &&
                new Date(order["updated_at"]).toLocaleDateString("id-ID", {
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "numeric",
                })}
            </span>
          </ModalContentDetail>
        </ModalContentWrapper>
        {order["file_url"] !== "" ? (
          <ModalContentWrapper>
            <h1>Files</h1>
            <ModalContentImage>
              <img src={order["file_url"]} alt="files" />
            </ModalContentImage>
          </ModalContentWrapper>
        ) : null}
      </ModalContent>
    </ModalWrapper>
  );
};
