import React, { useState } from "react";
import { toast } from "react-toastify";
import { login } from "../../../services/authService";
import { AuthButton, DataInputWrapper, FormWrapper } from "../style";

export const AuthForm = () => {
    const [data, setData] = useState({});

    const onChange = ({ currentTarget: input }) => {
        data[input.name] = input.value;
        setData(data);
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        if (data['identity'] === '') {
            toast.error("Email tidak boleh kosong")
            return
        }

        if (data['password'] === '') {
            toast.error("Kata Sandi tidak boleh kosong")
            return
        }

        try {
            const { status, data: resp } = await login(data);
            if (status === 200) {
                const token = resp.data['token'];
                localStorage.setItem("token", token);
                setTimeout(() => {
                    window.location = "/home"
                }, 700)
            }
        } catch (e) {
            if (e.response.status && e.response.status >= 400) {
                toast.error(e.response.data.message)
            }
        }
    }

    return (
        <FormWrapper>
            <form onSubmit={onSubmit}>
                <DataInputWrapper>
                    <label>Email</label>
                    <input type="text" name="identity" onChange={onChange} />
                </DataInputWrapper>
                <DataInputWrapper>
                    <label>Password</label>
                    <input type="password" name="password" onChange={onChange} />
                </DataInputWrapper>
                <AuthButton>
                    Sign In
                </AuthButton>
            </form>
        </FormWrapper>
    )
}