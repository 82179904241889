import React from "react";
import {
  ModalContent,
  ModalContentDetail,
  ModalContentWrapper,
  ModalHeader,
  ModalHeaderContainer,
  ModalWrapper,
  OrderItem,
  OrderItemDetail,
  OrderItemFlex,
  OrderItems,
} from "../../style";

export const InboundModal = ({ order }) => {
  return (
    <ModalWrapper>
      <ModalHeader>
        <ModalHeaderContainer>
          <h1>Order Details</h1>
        </ModalHeaderContainer>
      </ModalHeader>
      <ModalContent>
        <ModalContentWrapper>
          <h1>Detail</h1>
          <ModalContentDetail>
            <h2>Owner</h2>
            <span>{order["source"]}</span>
          </ModalContentDetail>
          <ModalContentDetail>
            <h2>PO Number</h2>
            <span>{order["po_number"]}</span>
          </ModalContentDetail>
          <ModalContentDetail>
            <h2>Order Number</h2>
            <span>{order["order_number"]}</span>
          </ModalContentDetail>
          <ModalContentDetail>
            <h2>Status</h2>
            <span>{order["status"]}</span>
          </ModalContentDetail>
          <ModalContentDetail>
            <h2>Delivery Date</h2>
            <span>
              {new Date(order["delivery_date"]).toLocaleDateString("id-ID", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              })}
            </span>
          </ModalContentDetail>
          <ModalContentDetail>
            <h2>Supplier</h2>
            <span>{order["supplier"]}</span>
          </ModalContentDetail>
        </ModalContentWrapper>
        <ModalContentWrapper>
          <h1>Items</h1>
          <OrderItems>
            {order["items"].map((item, index) => (
              <OrderItem key={index}>
                <OrderItemFlex style={{ flex: "2" }}>
                  <span>
                    {item["quantity"]} {item["uom"]}
                  </span>
                  <OrderItemDetail>
                    <h3>{item["product_name"]} </h3>
                    <span>
                      {item["batch"]} ({item["material_number"]})
                    </span>
                  </OrderItemDetail>
                </OrderItemFlex>
                <OrderItemFlex style={{ flex: "1" }}>
                  <OrderItemDetail>
                    <h3>Expired</h3>
                    <p>
                      {new Date(item["expiry_date"]).toLocaleDateString(
                        "id-ID",
                        {
                          day: "2-digit",
                          month: "long",
                          year: "numeric",
                        }
                      )}
                    </p>
                  </OrderItemDetail>
                  <OrderItemDetail>
                    <h3>Batch</h3>
                    <p>{item["batch"]}</p>
                  </OrderItemDetail>
                </OrderItemFlex>
              </OrderItem>
            ))}
          </OrderItems>
        </ModalContentWrapper>
      </ModalContent>
    </ModalWrapper>
  );
};
