import React from "react";
import { Sidebar } from "../../components/sidebar";
import { InventorySection } from "../../sections/inventory";
import { MainPageWrapper } from "../main/style";

export const InventoryPage = () => {
    return (
        <MainPageWrapper>
            <Sidebar />
            <InventorySection />
        </MainPageWrapper>
    )
}