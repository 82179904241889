import styled from "styled-components";

export const FormWrapper = styled.div`
    margin-top: 1.5rem;
`;

export const DataInputWrapper = styled.div`
    margin-bottom: 1rem;
    position: relative;

    & > label {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: .5rem;
        display: inline-block;
        color: #222;
    }

    & > input, & > textarea, & > select {
        color: rgb(107 114 128);
        width: 100%;
        border: 0;
        font-size: 14px;
        padding: 0 12px;
        border-radius: 8px;
        border: 1px solid rgb(209 213 219);
        height: 32px;

        &:focus {
            outline: none;
        }

        &:disabled {
            background-color: #EBEBE4;
        }
    }

    & > input {
        height: 2.5rem;
    }
    `;


export const AuthButton = styled.button`
    background-color: #3e79f7;
    box-shadow: 0 2px 0 rgba(5, 122, 255, 0.06);
    color: #fff;
    height: 40px;
    padding: 8px 15px;
    border-radius: 10px;
    font-size: 14px;
    outline: none;
    border: 0;
    width: 100%;
    margin-top: 6px;
`;

export const EyesWrapper = styled.div`
    position: absolute;
    right: 15px;
    bottom: 10px;
`;