import styled from "styled-components";

export const MainSectionWrapper = styled.div`
    background-color: transparent;
    border-left: 1px solid rgb(229 231 235);
    min-height: 100vh;
    width: 100%;
`;

export const MainSectionNavbar = styled.nav`
    background-color: #fff;
    position: sticky;
    top: 0;
    z-index: 30;
    width: 100%;
    border-bottom: 1px solid rgb(229 231 235);
    height: 4rem;
    padding: 0 2rem;
`;

export const MainSectionNavbarContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
`;

export const AuthWrapper = styled.div`
    display: flex;
    gap: 8px;
`;

export const Avatar = styled.div`
    & > img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
    }
`;

export const AvatarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;

    & > span {
        font-size: .75rem;
    }

    & > p {
        font-weight: 500;
        font-size: .875rem;
        font-weight: 700;
    }
`;

export const Card = styled.div`
  box-shadow: 0 0 6px #ebeef4;
  margin-bottom: 24px;
  background-color: #fff;
  border: 1px solid #eff0f2;
  border-radius: .6rem;
  background-clip: border-box;
  margin-top: 24px;
  width: 100%;
`;


export const CardBody = styled.div`
  padding: 1.5rem;

  & > h2 {
    font-size: 21px;
    font-weight: 600;
  }
`;

export const CardFilterWrapper = styled.div`
    box-shadow: #919eab4d 0 0 2px, #919eab1f 0 12px 24px -4px;
    width: 100%;
    padding: 1.5rem;
    border-radius: 8px;
    background-color: #fff;
`;

export const CardFilterContainer = styled.div`
    display: flex;
`;

export const MainContent = styled.div`
    padding: 1rem;
    width: 100%;
`;

export const MainContentHeader = styled.div`
    margin-bottom: 2rem;
`;

export const MainHeaderGreet = styled.div`
    & > h1 {
        font-size: 18px;
        font-weight: 700;
    }

    & > p {
        font-size: 14px;
    }
`;

export const ContentHeaderChart = styled.div`
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    width: 100%;
`;

export const ChartWrapper = styled.div`
    background-color: #fff;
    border-radius: 8px;
    font-family: 'Rubik', sans-serif!important;
    width: 100%;
`;

export const ChartHeader = styled.div`
    display: flex;
    padding: 1rem;

    & > h1 {
        font-size: 16px;
        font-weight: 700;
    }
`;

export const RowHeader = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    gap: 2rem;
`;

export const CardWrapper = styled.div`
    box-shadow: 0 0 #0000 ,0 0 #0000, 4px 4px 0 0 #000!important;
    padding: 1rem;
    border-radius: 8px;
    border: 1px solid rgb(17 24 39);
    background-color: #fff;
    width: 100%;
`;

export const CardContent = styled.div`
    display: flex;
    flex-direction: column;

    & > h1 {
        font-size: 15px;
        margin-bottom: 1.5rem;
    }

    & > h2 {
        font-size: 36px;
        font-weight: 700;
        margin: 0;
    }

    & > span {
        color: #72849a;
        font-size: 13px;
        margin-top: 8px;
    }
`;