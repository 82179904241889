import { createGlobalStyle } from "styled-components";


export const GlobalStyle = createGlobalStyle`
    *, *::before, *::after {
        box-sizing: border-box;
    }

    * {
        padding: 0;
        margin: 0;
        font-family: 'Inter', sans-serif;
        line-height: 1.2;
        scroll-behavior: smooth;
        letter-spacing: -.2px;
    }

    p {
        margin: 0;
    }

    body {
        background-color: rgb(243 244 246/1);
    }

    .modal-body {
        padding: 0!important;
    }

    h1, h2, h3, h4, th, tspan {
        font-family: 'Plus Jakarta Sans', sans-serif;
    }

    .page-item.active .page-link {
        background-color: #333;
        border-color: #333;
        font-size: 13px;
        z-index: 0;
    }

    .page-link:hover {
        color: #333;
    }

    .page-link {
        color: #333;
        font-size: 13px;
    }

  
    .page-link:focus,
    a:focus {
        outline: 0;
        box-shadow: none;
    }
`;