import React from "react";
import { Sidebar } from "../../components/sidebar";
import { OutboundList } from "../../sections/order/outbound";
import { MainPageWrapper } from "../main/style";

export const OutboundPage = () => {
    return (
        <MainPageWrapper>
            <Sidebar />
            <OutboundList />
        </MainPageWrapper>
    )
}