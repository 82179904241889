import React, { useEffect, useState } from "react";
import * as Md from "react-icons/md";
import Select from "react-select";
import { Navbar } from "../../components/navbar";
import Pagination from "../../components/pagination";
import { getAuthHeaders } from "../../services/authService";
import { getInventory } from "../../services/inventoryService";
import { paginate } from "../../utils/utils";
import { Card, CardBody, MainSectionWrapper } from "../main/style";
import {
    ButtonDownload,
    FilterContainer,
    FilterFlex,
    FilterFlexSmall,
    FilterWrapper,
    PaginationWrapper,
    SearchBar,
    SectionWrapper,
    StatusWrapper,
    StyledTable,
    TableDataWrapper,
    getStatusColor,
    selectStyles,
} from "../style";

const apiEndpoint = process.env.REACT_APP_PRAMBANAN_API_ENDPOINT;

const listFilter = [
    {
        value: 1,
        label: "Pondok Labu",
    },
    {
        value: 2,
        label: "Pluit",
    },
];

const listFilterSearch = [
    {
        value: "sku",
        label: "SKU Code",
    },
    {
        value: "description",
        label: "Product Name",
    },
    {
        value: "batch",
        label: "Batch",
    },
];

export const InventorySection = () => {
    const [inventories, setInventories] = useState([]);
    const [filteredInventories, setFilteredInventories] = useState([]);
    const [paginatedInventories, setPaginatedInventories] = useState([]);
    const [keyFilter, setKeyFilter] = useState("sku");
    const [pageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const fetchInventories = async () => {
        try {
            const { status, data } = await getInventory();
            if (status === 200) {
                setInventories(data.data);
                setFilteredInventories(data.data);
                const paginatedInventories = paginate(data.data, currentPage, pageSize);
                setPaginatedInventories(paginatedInventories);
            }
        } catch (e) { }
    };

    useEffect(() => {
        fetchInventories();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOrderSelection = (value) => {
        if (value == null) {
            const paginatedInventories = paginate(inventories, currentPage, pageSize);
            setPaginatedInventories(paginatedInventories);
            setFilteredInventories(inventories);
            return;
        }
        const filtered = inventories.filter(
            (inventory) => inventory["warehouse_id"] === value.value
        );
        setFilteredInventories(filtered);
        const paginatedInventories = paginate(filtered, currentPage, pageSize);
        setPaginatedInventories(paginatedInventories);
    };

    const handleKeySelection = (value) => {
        setKeyFilter(value.value);
    };

    const handleSearch = ({ currentTarget: input }) => {
        const filtered = inventories.filter((invent) =>
            invent[`${keyFilter}`].toLowerCase().includes(input.value.toLowerCase())
        );
        setFilteredInventories(filtered);
        const paginatedInventories = paginate(filtered, currentPage, pageSize);
        setPaginatedInventories(paginatedInventories);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        const paginatedInventories = paginate(
            filteredInventories,
            currentPage,
            pageSize
        );
        setPaginatedInventories(paginatedInventories);
    };

    const handleDownload = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(
                `${apiEndpoint}/client/document/inventory`,
                getAuthHeaders()
            );
            const blob = await response.blob();
            const contentDisposition = response.headers.get("Content-Disposition");
            const filename = contentDisposition
                .split("filename=")[1]
                .replace(/"/g, "");
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");

            a.href = url;
            a.download = filename;

            document.body.appendChild(a);
            a.click();
            a.remove();
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <MainSectionWrapper>
            <Navbar />
            <SectionWrapper>
                <h1>Inventory</h1>
                <Card>
                    <CardBody>
                        <FilterFlex>
                            <FilterWrapper>
                                <h1>Warehouse</h1>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    defaultValue={listFilter[0]}
                                    isDisabled={false}
                                    isLoading={false}
                                    isClearable={true}
                                    isRtl={false}
                                    isSearchable={true}
                                    name="so_number"
                                    options={listFilter}
                                    onChange={handleOrderSelection}
                                    styles={selectStyles}
                                />
                            </FilterWrapper>
                            <FilterWrapper>
                                <h1>Search</h1>
                                <FilterContainer>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        defaultValue={listFilterSearch[0]}
                                        isDisabled={false}
                                        isLoading={false}
                                        isClearable={true}
                                        isRtl={false}
                                        isSearchable={true}
                                        name="key"
                                        options={listFilterSearch}
                                        onChange={handleKeySelection}
                                        styles={selectStyles}
                                    />
                                    <SearchBar style={{ width: "300px" }}>
                                        <svg
                                            style={{ stroke: "#1a202c" }}
                                            width="21"
                                            height="22"
                                            viewBox="0 0 21 22"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <circle
                                                cx="9.80204"
                                                cy="10.6761"
                                                r="8.98856"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            ></circle>
                                            <path
                                                d="M16.0537 17.3945L19.5777 20.9094"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            ></path>
                                        </svg>
                                        <input
                                            type="text"
                                            placeholder="Cari Order Berdasarkan PO atau Order Number ..."
                                            onChange={handleSearch}
                                        />
                                    </SearchBar>
                                </FilterContainer>
                            </FilterWrapper>
                            <FilterWrapper>
                                <h1>Download Excel</h1>
                                <FilterFlexSmall>
                                    <ButtonDownload onClick={handleDownload}>
                                        <Md.MdDownload />
                                        <span>Download</span>
                                    </ButtonDownload>
                                </FilterFlexSmall>
                            </FilterWrapper>
                        </FilterFlex>
                        <StyledTable>
                            <colgroup>
                                <col style={{ width: "90px" }} />
                                <col style={{ width: "50px" }} />
                                <col style={{ width: "60px" }} />
                                <col style={{ width: "50px" }} />
                                <col style={{ width: "80px" }} />
                                <col style={{ width: "30px" }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>SKU Code</th>
                                    <th>Quantity / Expiry</th>
                                    <th>Batch</th>
                                    <th>Warehouse</th>
                                    <th>Status</th>
                                    <th>Owner</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedInventories.map((inventory, index) => (
                                    <tr key={index}>
                                        <td>
                                            <TableDataWrapper>
                                                <h1>{inventory["sku"]}</h1>
                                                <span>{inventory["description"]}</span>
                                            </TableDataWrapper>
                                        </td>
                                        <td>
                                            <TableDataWrapper>
                                                <h1>{inventory["quantity"]} {inventory["uom"]}</h1>
                                                <span> {new Date(inventory["expiry_date"]).toLocaleDateString(
                                                    "id-ID",
                                                    {
                                                        day: "2-digit",
                                                        month: "short",
                                                        year: "numeric",
                                                    }
                                                )}</span>
                                            </TableDataWrapper>
                                        </td>
                                        <td>{inventory["batch"]}</td>
                                        <td>
                                            {inventory["warehouse_id"] === 2
                                                ? "Pluit"
                                                : "Pondok Labu"}
                                        </td>
                                        <td>
                                            <StatusWrapper
                                                color={getStatusColor(inventory["status"])}
                                            >
                                                <span>{inventory["status"]}</span>
                                            </StatusWrapper>
                                        </td>
                                        <td>{inventory["source"]}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </StyledTable>
                        <PaginationWrapper>
                            <Pagination
                                itemsCount={inventories.length}
                                pageSize={pageSize}
                                onPageChange={handlePageChange}
                            />
                        </PaginationWrapper>
                    </CardBody>
                </Card>
            </SectionWrapper>
        </MainSectionWrapper>
    );
};
