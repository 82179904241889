import styled from "styled-components";

export const ModalWrapper = styled.div`
`;

export const ModalHeader = styled.div`
    border-bottom: 1px solid #eee;
`;

export const ModalHeaderContainer = styled.div`
    padding: 1rem;

    & > h1 {
        font-size: 16px;
        margin: 0;
        font-weight: 700;
    }
`;

export const ModalContent = styled.div`
    padding: 1rem;
`;

export const ModalContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    border-bottom: 1px solid #eee;
    padding-bottom: 1rem;

    & > h1 {
        font-size: 14px;
        margin: 0;
        margin-bottom: 10px;
        font-weight: 700;
    }
`;

export const ModalContentImage = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 150px));

    & > img {
        border-radius: 8px;
        width: 100%;
    }
`;

export const ModalContentDetail = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;

    & > h2 {
        font-size: 13px;
        font-weight: 500!important;
        color: #222;
        margin: 0;
    }

    & > span {
        font-size: 12px;
        font-weight: 400;
    }
`;

export const OrderItems = styled.div`
    display: grid;
    gap: 8px;
    margin-top: 8px;
`;

export const OrderItemFlex = styled.div`
    display: flex;
    gap: 1.25rem;

    & > span {
        border: 1px solid #eee;
        padding: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        font-size: 11px;
        align-self: flex-start;
        font-weight: 600;
        width: 30px;
    }
`;

export const OrderItem = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 8px;
`;

export const OrderItemDetail = styled.div`
    display: flex;
    flex-direction: column;

    & > h3 {
        font-size: 12.4px;
        margin-bottom: 4px;
        line-height: 1.3;
        font-weight: 600;
    }

    & > span {
        font-size: 11px;
    }

    & > p {
        font-size: 11px;
    }
`;  