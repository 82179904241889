import React, { useEffect, useState } from "react";
import * as Md from "react-icons/md";
import Select from "react-select";
import { toast } from "react-toastify";
import { ModalComponent } from "../../../components/modal";
import { InboundModal } from "../../../components/modal/order/inbound";
import { Navbar } from "../../../components/navbar";
import Pagination from "../../../components/pagination";
import { getAuthHeaders } from "../../../services/authService";
import { getInboundOrders } from "../../../services/orderService";
import { paginate } from "../../../utils/utils";
import {
  Card,
  CardBody,
  CardFilterContainer,
  CardFilterWrapper,
  MainSectionWrapper,
} from "../../main/style";
import {
  ButtonDownload,
  FilterFlex,
  FilterFlexSmall,
  FilterWrapper,
  PaginationWrapper,
  SearchBar,
  SectionWrapper,
  StatusWrapper,
  StyledTable,
  getStatusColor,
  selectStyles,
} from "../../style";

const apiEndpoint = process.env.REACT_APP_PRAMBANAN_API_ENDPOINT;

const listFilter = [
  {
    value: "Tersimpan",
    label: "Tersimpan",
  },
  {
    value: "Karantina",
    label: "Karantina",
  },
];

export const InboundList = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [paginatedOrders, setPaginatedOrders] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [pageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchInboundOrders = async () => {
    try {
      const { status, data } = await getInboundOrders();
      if (status === 200) {
        setOrders(data.data);
        setFilteredOrders(data.data);
        const paginatedOrders = paginate(data.data, currentPage, pageSize);
        setPaginatedOrders(paginatedOrders);
      }
    } catch (e) { }
  };

  useEffect(() => {
    fetchInboundOrders();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOrderSelection = (value) => {
    if (value == null) {
      setFilteredOrders(orders);
      const paginatedOrders = paginate(orders, currentPage, pageSize);
      setPaginatedOrders(paginatedOrders);
      return;
    }
    const filtered = orders.filter((order) => order["status"] === value.value);
    setFilteredOrders(filtered);
    const paginatedOrders = paginate(filtered, currentPage, pageSize);
    setPaginatedOrders(paginatedOrders);
  };

  const onChange = ({ currentTarget: input }) => {
    const filtered = orders.filter(
      (order) =>
        order["po_number"].includes(input.value) ||
        order["order_number"].includes(input.value)
    );
    setFilteredOrders(filtered);
    const paginatedOrders = paginate(filtered, currentPage, pageSize);
    setPaginatedOrders(paginatedOrders);
  };

  const onDateChange = ({ currentTarget: input }) => {
    if (input["name"] === "start_date") {
      const filtered = orders.filter(
        (order) => new Date(order["delivery_date"]) >= new Date(input["value"])
      );
      setFilteredOrders(filtered);
      const paginatedOrders = paginate(filtered, currentPage, pageSize);
      setPaginatedOrders(paginatedOrders);
    } else {
      const filtered = orders.filter(
        (order) => new Date(order["delivery_date"]) <= new Date(input["value"])
      );
      setFilteredOrders(filtered);
      const paginatedOrders = paginate(filtered, currentPage, pageSize);
      setPaginatedOrders(paginatedOrders);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    const paginatedOrders = paginate(filteredOrders, page, pageSize);
    setPaginatedOrders(paginatedOrders);
  };

  const handleDownload = async (e) => {
    e.preventDefault();

    if (!startDate || !endDate) {
      toast.error("Pilih Tanggal dahulu!");
      return;
    }

    try {
      const response = await fetch(
        `${apiEndpoint}/client/document/inbound?start_date=${startDate}&end_date=${endDate}`,
        getAuthHeaders()
      );
      const blob = await response.blob();
      const contentDisposition = response.headers.get("Content-Disposition");
      const filename = contentDisposition
        .split("filename=")[1]
        .replace(/"/g, "");
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");

      a.href = url;
      a.download = filename;

      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <MainSectionWrapper>
      <Navbar />
      <SectionWrapper>
        <h1>Inbound Order</h1>
        <CardFilterWrapper>
          <CardFilterContainer>
            <FilterWrapper>
              <h1>Search</h1>
              <SearchBar>
                <svg
                  style={{ stroke: "#1a202c" }}
                  width="21"
                  height="22"
                  viewBox="0 0 21 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="9.80204"
                    cy="10.6761"
                    r="8.98856"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></circle>
                  <path
                    d="M16.0537 17.3945L19.5777 20.9094"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
                <input
                  type="text"
                  placeholder="Cari Order Berdasarkan PO atau Order Number ..."
                  onChange={onChange}
                />
              </SearchBar>
            </FilterWrapper>
          </CardFilterContainer>
        </CardFilterWrapper>
        <Card>
          <CardBody>
            <FilterFlex>
              <FilterWrapper>
                <h1>Status</h1>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={listFilter[0]}
                  isDisabled={false}
                  isLoading={false}
                  isClearable={true}
                  isRtl={false}
                  isSearchable={true}
                  name="so_number"
                  options={listFilter}
                  onChange={handleOrderSelection}
                  styles={selectStyles}
                />
              </FilterWrapper>
              <FilterWrapper>
                <h1>Delivery Date</h1>
                <FilterFlexSmall>
                  <input
                    type="date"
                    name="start_date"
                    onChange={onDateChange}
                  />
                  <span>-</span>
                  <input type="date" name="end_date" onChange={onDateChange} />
                </FilterFlexSmall>
              </FilterWrapper>
              <FilterWrapper>
                <h1>Download Excel</h1>
                <FilterFlexSmall>
                  <input
                    type="date"
                    name="download_date_start"
                    onChange={({ currentTarget: input }) =>
                      setStartDate(input.value)
                    }
                  />
                  <span>-</span>
                  <input
                    type="date"
                    name="download_date_end"
                    onChange={({ currentTarget: input }) =>
                      setEndDate(input.value)
                    }
                  />
                  <ButtonDownload onClick={handleDownload}>
                    <Md.MdDownload />
                    <span>Download</span>
                  </ButtonDownload>
                </FilterFlexSmall>
              </FilterWrapper>
            </FilterFlex>
            <StyledTable>
              <colgroup>
                <col style={{ width: "50px" }} />
                <col style={{ width: "50px" }} />
                <col style={{ width: "50px" }} />
                <col style={{ width: "40px" }} />
                <col style={{ width: "60px" }} />
                <col style={{ width: "70px" }} />
                <col style={{ width: "30px" }} />
              </colgroup>
              <thead>
                <tr>
                  <th>PO Number</th>
                  <th>Order Number</th>
                  <th>Tanggal Kirim</th>
                  <th>Total Item</th>
                  <th>Status</th>
                  <th>Supplier</th>
                  <th>Owner</th>
                </tr>
              </thead>
              <tbody>
                {paginatedOrders.map((order, index) => (
                  <tr key={index}>
                    <td
                      onClick={() => {
                        setSelectedOrder(order);
                        setIsOpenModal(true);
                      }}
                    >
                      <span>{order["po_number"]}</span>
                    </td>
                    <td>{order["order_number"]}</td>
                    <td>
                      {new Date(order["delivery_date"]).toLocaleDateString(
                        "id-ID",
                        {
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                        }
                      )}
                    </td>
                    <td>{order["items"].length}</td>
                    <td>
                      <StatusWrapper color={getStatusColor(order["status"])}>
                        <span>{order["status"]}</span>
                      </StatusWrapper>
                    </td>
                    <td>{order["supplier"]}</td>
                    <td>{order["source"]}</td>
                  </tr>
                ))}
              </tbody>
            </StyledTable>
            <PaginationWrapper>
              <Pagination
                itemsCount={orders.length}
                pageSize={pageSize}
                onPageChange={handlePageChange}
              />
            </PaginationWrapper>
          </CardBody>
        </Card>
      </SectionWrapper>
      <ModalComponent
        show={isOpenModal}
        onHide={() => setIsOpenModal(!isOpenModal)}
        body={<InboundModal order={selectedOrder} />}
      />
    </MainSectionWrapper>
  );
};
