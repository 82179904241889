import styled from "styled-components";

export const SectionWrapper = styled.div`
    padding: 1rem;

    & > h1 {
      font-size: 1.5rem;
      line-height: 2rem;
      font-weight: 600;
      margin: 1rem 0 1.5rem;
    }
`;


export const StyledTable = styled.table`
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  margin-top: 12px;

  th,
  td {
    //border: 1px solid #ddd;
    padding: 10px 14px;
    text-align: left;
  }

  td {
    position: relative;
    font-weight: 400;
    color: #545965;

    & > span {
      text-decoration-color: #222;
      color: #222;
      cursor: pointer;
    }
  }

  input {
    max-width: 100%;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #ccc;
    padding: 4px;
  }

  //tr:nth-child(even) {
  //  background-color: #f2f2f2;
  //}

  tr {
    /* border-bottom: 1px solid #eff0f2; */
  }

  th {
    background-color: #fff;
    color: #222;
    font-size: 13px;
    font-weight: 700;
    border-bottom: 1px solid #eee;
  }

  th:first-child {
    border-top-left-radius: 8px;
  }

  th:last-child{
    border-top-right-radius: 8px;
  }

  td {
    font-size: 13px;
    color: #000;
    /* border-bottom: 1px solid #eee; */
    background-color: #fff;
  }
`;

export const StatusWrapper = styled.div`
    background-color: ${({ color }) => color};
    padding: 0 8px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 150px;
    height: 32px;

    & > span {
        color: #333;
        font-weight: 600;
        font-size: 13px;
    }
`;

export const FilterFlex = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  margin-bottom: 1.5rem;
`;

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  & > h1 {
    font-size: 14px;
    font-weight: 800;
  }
`;

export const FilterFlexSmall = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  &>input[type="date"] {
    border: 0;
    padding: 0 18px;
    background-color: #fafafa;
    min-height: 45px;
    border-radius: 6px;
    font-size: 13px;
  }
`;


export const SearchBar = styled.div`
    display: flex;
    padding: 12px 16px;
    border-radius: 8px;
    /* border: 1px solid rgb(208 209 211); */
    width: 400px;
    background-color: #fafafa;
    gap: 10px;

    & > input {
        width: 100%;
        border: 0;
        font-size: 13px;
        background: transparent;
        font-weight: 500;

        &:focus {
            outline: none;
        }
    }
`;

export const SearchIcon = styled.div`
    height: 1.5rem;
    width: 1.5rem;
    background-color: #3e79f7;
    border-radius: 4px;
    color: #fff!important;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ButtonDownload = styled.button`
  background-color: #5d87ff;
  height: 40px;
  padding: 0 16px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  color: #fff;
  gap: 4px;

  & > span {
    font-size: 13px;
  }
`;

export const PaginationWrapper = styled.div`
  margin-top: 1.5rem;
`;

export const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    background: "#fff",
    borderColor: "#ddd",
    minHeight: "40px",
    height: "40px",
    fontSize: "13px",
    width: "210px",
    boxShadow: state.isFocused ? null : null,
  }),
  option: (provided) => ({
    ...provided,
    fontSize: "13px",
  })
};

export const getStatusColor = (status) => {
  switch (status) {
    case "Pengiriman Selesai": return "rgb(249 224 91)"
    case "Proses Outbound": return "#ecf2ff"
    case "Dalam Pengiriman": return "#13deb9"
    default: return "rgb(249 224 91)"
  }
}

export const TableDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;

  & > h1 {
    font-size: 13px;
    font-weight: 700;
  }

  & > span {
    text-decoration: none;
    font-size: 13px;
  }
`;