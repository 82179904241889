import React from "react";
import { Sidebar } from "../../components/sidebar";
import { MainSection } from "../../sections/main";
import { MainPageWrapper } from "./style";

export const MainPage = () => {
    return (
        <MainPageWrapper>
            <Sidebar />
            <MainSection />
        </MainPageWrapper>
    )
}