import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Navbar } from "../../components/navbar";
import Pagination from "../../components/pagination";
import { getProducts } from "../../services/productService";
import { paginate } from "../../utils/utils";
import { Card, CardBody, MainSectionWrapper } from "../main/style";
import {
  FilterContainer,
  FilterFlex,
  FilterWrapper,
  PaginationWrapper,
  SearchBar,
  SectionWrapper,
  StyledTable,
  TableDataWrapper,
  selectStyles,
} from "../style";

const listFilterSearch = [
  {
    value: "code",
    label: "SKU",
  },
  {
    value: "name",
    label: "Name",
  },
  {
    value: "category",
    label: "Category",
  },
];

export const ProductSection = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [paginatedProducts, setPaginatedProducts] = useState([]);
  const [keyFilter, setKeyFilter] = useState("sku");
  const [pageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchProducts = async () => {
    try {
      const { status, data } = await getProducts();
      if (status === 200) {
        setProducts(data.data);
        setFilteredProducts(data.data);
        const paginated = paginate(data.data, currentPage, pageSize);
        setPaginatedProducts(paginated);
      }
    } catch (e) { }
  };

  useEffect(() => {
    fetchProducts();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleKeySelection = (value) => {
    setKeyFilter(value.value);
  };

  const handleSearch = ({ currentTarget: input }) => {
    const filtered = products.filter((product) =>
      product[`${keyFilter}`].toLowerCase().includes(input.value.toLowerCase())
    );
    setFilteredProducts(filtered);
    const paginated = paginate(filtered, currentPage, pageSize);
    setPaginatedProducts(paginated);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    const paginated = paginate(filteredProducts, page, pageSize);
    setPaginatedProducts(paginated);
  };

  return (
    <MainSectionWrapper>
      <Navbar />
      <SectionWrapper>
        <h1>Products</h1>
        <Card>
          <CardBody>
            <FilterFlex>
              <FilterWrapper>
                <h1>Search</h1>
                <FilterContainer>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={listFilterSearch[0]}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    name="key"
                    options={listFilterSearch}
                    onChange={handleKeySelection}
                    styles={selectStyles}
                  />
                  <SearchBar>
                    <svg
                      style={{ stroke: "#1a202c" }}
                      width="21"
                      height="22"
                      viewBox="0 0 21 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="9.80204"
                        cy="10.6761"
                        r="8.98856"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></circle>
                      <path
                        d="M16.0537 17.3945L19.5777 20.9094"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    <input
                      type="text"
                      placeholder="Cari berdasarkan SKU, Nama Produk atau Batch ..."
                      onChange={handleSearch}
                    />
                  </SearchBar>
                </FilterContainer>
              </FilterWrapper>
            </FilterFlex>
            <StyledTable>
              <colgroup>
                <col style={{ width: "70px" }} />
                <col style={{ width: "40px" }} />
                <col style={{ width: "40px" }} />
                <col style={{ width: "50px" }} />
                <col style={{ width: "50px" }} />
              </colgroup>
              <thead>
                <tr>
                  <th rowSpan={2}>SKU / Name</th>
                  <th rowSpan={2}>UOM</th>
                  <th rowSpan={2}>Category</th>
                  <th
                    colSpan="2"
                    style={{
                      textAlign: "center",
                      borderLeft: "1px solid #eee",
                      borderRight: "1px solid #eee",
                    }}
                  >
                    UOM Conversion
                  </th>
                </tr>
                <tr>
                  <th
                    style={{
                      textAlign: "center",
                      borderLeft: "1px solid #eee",
                      borderRight: "1px solid #eee",
                    }}
                  >
                    Weight (Gr)
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      borderRight: "1px solid #eee",
                    }}
                  >
                    Tray
                  </th>
                </tr>
              </thead>
              <tbody>
                {paginatedProducts.map((product, index) => (
                  <tr key={index}>
                    <td>
                      <TableDataWrapper>
                        <h1>{product["code"]}</h1>
                        <span>{product["name"]}</span>
                      </TableDataWrapper>
                    </td>
                    <td>{product["default_uom"]}</td>
                    <td>{product["category"]}</td>
                    <td style={{ textAlign: "center" }}>
                      {product["uom_conversion"] &&
                        product["uom_conversion"].gr}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {product["uom_conversion"] &&
                        product["uom_conversion"].tray}
                    </td>
                  </tr>
                ))}
              </tbody>
            </StyledTable>
            <PaginationWrapper>
              <Pagination
                itemsCount={products.length}
                pageSize={pageSize}
                onPageChange={handlePageChange}
              />
            </PaginationWrapper>
          </CardBody>
        </Card>
      </SectionWrapper>
    </MainSectionWrapper>
  );
};
