import { getAuthHeaders } from "./authService";
import httpService from "./httpService";

export async function getInboundOrders() {
    return httpService.get("/client/orders/inbound", getAuthHeaders());
}

export async function getInboundOrdersChart(days) {
    let url = "/client/orders/inbound/chart";
    if (days) {
        url += `?days=${days}`
    }
    return httpService.get(url, getAuthHeaders());
}

export async function getOutboundOrdersChart(days) {
    let url = "/client/orders/outbound/chart";
    if (days) {
        url += `?days=${days}`
    }
    return httpService.get(url, getAuthHeaders());
}

export async function getInboundOutboundOrdersChart(days) {
    let url = "/client/orders/inbout/chart";
    if (days) {
        url += `?days=${days}`
    }
    return httpService.get(url, getAuthHeaders());
}

export async function getOutboundOrders() {
    return httpService.get("/client/orders/outbound", getAuthHeaders());
}