import React, { useEffect } from "react";
import { getAuthHeaders } from "../../services/authService";
import httpService from "../../services/httpService";

export const Logout = () => {
    const logout = async () => {
        try {
            const { status } = await httpService.delete("/client/user", getAuthHeaders());
            if (status === 200) {
                localStorage.removeItem("token");
                setTimeout(() => {
                    window.location = "/"
                }, 800)
            }
        } catch (e) {

        }
    }
    useEffect(() => {
        logout();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div></div>
    )
}