import styled from "styled-components";

export const SidebarWrapper = styled.div`
    position: sticky;
    width: 290px;
    height: 100vh;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
    background-color: #fff;
    box-shadow: rgba(52, 58, 64, 0.06) 0px -2px 5px;
`

export const SidebarContainer = styled.div`
    padding: 1rem;
    display: flex;
    flex-direction: column;
`;

export const SidebarMenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

export const SidebarFooter = styled.div`
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 4px;

    & > span {
        font-size: 11px;
        color: #222;
        opacity: .5;
    }
`;

export const SidebarHeader = styled.div`
    display: flex;
    justify-content: center;

    & > img {
        width: 150px;
    }
`;

export const SidebarMenuWrapper = styled.div`
    margin-top: 2rem;
`;

export const MenuTitle = styled.div`
    margin: 1rem 0 0;
    padding: .5rem .75rem;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
    border-bottom: 1px solid rgb(237 242 247);
    margin-bottom: 8px;
`;

export const MenuText = styled.a`
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 .75rem;
    padding-left: .75rem;
    cursor: pointer;
    font-weight: 500;
    font-size: 13px;
    text-decoration: none;
    color: #222;
    display: flex;
    align-items: center;
    gap: 8px;

    &:hover {
        background-color: rgb(17 24 39 / .1);
        color: rgb(17 24 39 / 1);
    }
`;